  // colors
  :root {
    /* -- base color prim -- */
    --color-prim-lighter: #a3aec2;
    --color-prim-light: #8594ad;
    --color-prim: #667999;
    --color-prim-dark: #52617a;
    --color-prim-darker: #3d485c;

    /* -- base color sec -- */
    --color-sec-lighter: #e8c17d;
    --color-sec-light: #e0ac52;
    --color-sec: #d99726;
    --color-sec-dark: #ad791f;
    --color-sec-darker: #ad791f;

    /* -- base color tri -- */
    --color-tri-lighter: #7de88b;
    --color-tri-light: #52e065;
    --color-tri: #26d93e;
    --color-tri-dark: #1fad32;
    --color-tri-darker: #178225;

    /* -- base color danger -- */
    --color-danger-lighter: #ff6673;
    --color-danger-light: #ff3344;
    --color-danger: #ff0015;
    --color-danger-dark: #cc0011;
    --color-danger-darker: #99000d;

    /* -- base color success -- */
    --color-success-lighter: #a8ff66;
    --color-success-light: #8bff33;
    --color-success: rgb(111, 255, 0);
    --color-success-dark: #58cc00;
    --color-success-darker: #429900;

    /* -- base color info -- */
    --color-info-lighter: #6699ff;
    --color-info-light: #3377ff;
    --color-info: #0055ff;
    --color-info-dark: #0044cc;
    --color-info-darker: #003399;

    /* -- base color warning -- */
    --color-warning-lighter: #ffdb66;
    --color-warning-light: #ffcf33;
    --color-warning: #ffc400;
    --color-warning-dark: #cc9c00;
    --color-warning-darker: #997500;

    // black + white
    --color-black: #000000;
    --color-white: #ffffff;
    --color-pale: #f1efee;
    --color-pale-1: #e5e2e0;
    --color-pale-2: #dad5d3;

    //greys
    --color-grey-1: #f5f5f5;
    --color-grey-2: #e0e0e0;
    --color-grey-3: #c7c7c7;
    --color-grey-4: #adadad;
    --color-grey-5: #999999;
    --color-grey-6: #858585;
    --color-grey-7: #737373;
    --color-grey-8: #616161;
    --color-grey-9: #4f4f4f;
    --color-grey-10: #3d3d3d;
    --color-grey-11: #2b2b2b;
    --color-grey-12: #1a1a1a;

    --color-orange: #ff9500;

    --background-color: var(--color-white);
    --font-color: var(--color-grey-12);
}
