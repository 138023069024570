/* -- content -- */
main {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: var(--space-unit);

  @supports(display: grid) {
    .auto__grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size, 16rem), 1fr));
      grid-gap: 1rem;
    }

    .auto__grid>* {
      max-width: unset;
      margin: unset;
    }
  }

  a {
    color: var(--color-prim);
    text-decoration: underline;
    background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
    background-repeat: repeat-y;
    background-position: 0 100%;
    background-size: 0% 0em;
    transition: 600ms ease;

    &:focus,
    &:hover {
      color: var(--color-white);
      text-decoration: none;
      background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
      background-size: 100% 1em;
    }
  }


  section,
  article {
    max-width: var(--content-width);
    margin: 0 auto;
    padding: var(--space-unit);
  }

  .auto__grid>* {
    max-width: 25rem;
    margin-right: auto;
    margin-left: auto;

    img {
      min-width: 100%;
      object-fit: cover;
    }
  }

  .auto__grid>*+* {
    margin-top: 1rem;
  }

  .auto__grid>* {
    max-width: unset;
    margin: unset;
  }

  @supports(display: grid) {
    .auto__grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size, 16rem), 1fr));
      grid-gap: 1rem;
    }
  }
}


@include breakpoint(md) {
  main {
    padding: var(--space-lg) var(--space-unit) var(--space-unit);
  }
}


@include breakpoint(lg) {
  main {
    padding: 3rem var(--space-unit) var(--space-15);
  }
}

@include breakpoint(xl) {
  main {
    padding: 3rem var(--space-unit) var(--space-15);
  }
}