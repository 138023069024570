/* -- copyright -- */

.copyright {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: var(--space-15) var(--space-xs);
  background: var(--color-pale-1);
  -ms-flex-pack: center;

  ul {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: var(--space-unit);
    -ms-flex-pack: center;

    li {
      margin: 0;
      padding: 0.4rem 0;
      text-align: center;
    }
  }

  p {
    text-align: center;
  }

  a {
    color: var(--color-prim);
    text-decoration: underline;
    background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
    background-repeat: repeat-y;
    background-position: 0 100%;
    background-size: 0% 0em;
    transition: 600ms ease;

    &:focus,
    &:hover {
      color: var(--color-white);
      text-decoration: none;
      background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
      background-size: 100% 1em;
    }
  }

  .icon {
    width: 1em;
    height: 1em;
    margin-right: 0.1em;
  }
}

@include breakpoint(md) {
  .copyright {
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 3em 5% 1em;
    -ms-flex-pack: center;

    ul {
      flex-flow: row wrap;
      justify-content: center;
      margin-top: -1.5em;
      margin-left: var(--space-unit);

      li {
        padding: 0 0.8rem;
      }
    }

    p {
      padding-bottom: 1em;
    }
  }
}