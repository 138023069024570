/* -- buttons -- */
// Default button

.button {
  margin: 0;
  padding: var(--space-xs) var(--space-md);
  text-align: center;
  color: var(--color-prim);
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  border: 1px solid var(--color-prim);
  border-radius: var(--button-border-radius);
  outline: none;
  cursor: pointer;
  transition: all 0.35s var(--transition);

  &:focus,
  &:hover {
    color: var(--color-white);
    text-decoration: none;
    background: var(--color-prim);
    border-color: var(--color-prim);
    border-radius: 5px;
  }

  &:active {
    background: var(--color-prim-dark);
    border-color: var(--color-prim-dark);
  }

  &.full__width {
    width: 100%;
  }
}

// prim action button

.button__primary {
  color: var(--color-white);
  background-color: var(--color-prim);
  border-color: var(--color-prim);

  &:focus,
  &:hover {
    color: var(--color-white);
    background: var(--color-prim-darker);
    border-color: var(--color-prim-darker);
    border-radius: 10px;
  }
}

// prim submit button

.button__submit {
  color: var(--color-white);
  background-color: var(--color-prim);
  border-color: var(--color-prim);

  &:focus,
  &:hover {
    color: var(--color-white);
    background: var(--color-prim-darker);
    border-color: var(--color-prim-darker);
    border-radius: 10px;
  }
}

.button__primary.loading {
  background: var(--color-grey-2);
  cursor: not-allowed;
  opacity: 0.65;
}

// Larger button

.button__large {
  padding: var(--space-unit) var(--space-xl);
  font-size: 1.4em;
}

// Smaller button

.button__small {
  padding: var(--space-xxxxs) var(--space-xxs);
  font-size: 0.85em;
}

// Link button - a button that looks like a link

.button__link {
  margin: 0.6rem;
  color: var(--color-prim);
  text-transform: none;
  text-shadow: none;
  background: transparent;
  border: 0;
  box-shadow: none;
  transition: all 0.35s var(--transition);

  &:focus,
  &:hover {
    color: var(--color-prim);
    text-decoration: underline;
    background: transparent;
  }
}