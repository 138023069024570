$breakpoints: (
  xs: 30rem,   // ~480px
  sm: 37.5rem, // ~600px
  md: 49.9rem, // ~798px
  lg: 64rem,   // ~1024px
  xl: 80rem,   // ~1280px
  xxl: 90rem    // ~1440px
);

@mixin breakpoint($breakpoint, $logic: false) {
  @if( $logic ) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) { @content; }
  }
}

$breakpoint-xs: 30rem;
$breakpoint-sm: 37.5rem;
$breakpoint-md: 49.9rem;
$breakpoint-lg: 64rem;
$breakpoint-xl: 80rem;
$breakpoint-xxl: 90rem;