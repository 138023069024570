/* -- prefooter -- */

.prefooter {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 2rem;
    color: var(--color-grey-12);
    background: var(--color-pale);

    li {
        margin: 0.4rem;
    }

    .icon {
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        margin: 0 auto;
        color: var(--color-grey-12);
        transition: all 0.35s var(--transition);

        &:focus,
        &:hover {
            transform: scale(1.2);
        }
    }

    a {
        padding: 0.3rem 0;
        color: var(--color-white);
        text-decoration: none;
        white-space: nowrap;
        transition: all 0.35s var(--transition);

        &:focus,
        &:hover {
            color: var(--color-grey-3);
        }
    }

    img {
        height: 35px;
        transition: all 0.35s var(--transition);

        &:focus,
        &:hover {
            transform: scale(1.3);
        }
    }
}


@include breakpoint(md) {
    .prefooter {
        display: flex;
        display: -ms-flexbox;
        flex-direction: row;
        padding: 0 0 1rem;

        ul {
            display: flex;
            display: -ms-flexbox;
            flex-direction: row;

            li {
                margin: 1rem;
            }
        }

        .icon {
            width: 1.75rem;
            height: 1.75rem;
        }

        img {
            height: 45px;
        }

        hr {
            display: none;
        }
    }
}


@include breakpoint(lg) {
    .prefooter {

        ul {

            li {
                margin: 2rem;
            }
        }

        .icon {
            width: 2.5rem;
            height: 2.5rem;
        }

        img {
            height: 55px;
        }
    }
}