/* -- pagination -- */

main {
  section {
    .paging {
      display: -ms-flexbox;
      display: flex;
      flex-flow: row;
      align-content: center;
      margin-top: 2.5rem;

      ul {
        display: -ms-flexbox;
        display: flex;
        flex-flow: row;
        margin-top: 0.4rem;

        li {
          margin: 0 0.4rem;
          line-height: 1;

          a {
            position: relative;
            top: -0.1rem;
            margin: 0 0.3rem;
            padding: 0.4rem 0.6rem;
            color: var(--color-white);
            background: var(--color-prim);
            border-radius: var(--border-radius);
            border-bottom: none;
            box-shadow: none;

            &:focus,
            &:hover {
              border-bottom: none;
              box-shadow: none;
              color: var(--color-white);
              background: var(--color-prim);
            }
          }
        }
      }

      a {
        position: relative;
        top: -0.7rem;
        margin: 0.4rem 0.3rem 0;
        padding: 0.35rem 0.6rem;
        color: var(--color-white);
        background: var(--color-prim);
        border-radius: var(--border-radius);
        border-bottom: none;
        box-shadow: none;

        &:focus,
        &:hover {
          border-bottom: none;
          box-shadow: none;
          color: var(--color-white);
          background: var(--color-prim);
        }
      }
    }
  }
}

@include breakpoint(md) {
  main {
    section {
      .paging {
        a {
          padding: 0.25rem 0.6rem;
        }
      }
    }
  }
}
