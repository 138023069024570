// Other Useful Reusable Styles

.text__center {
  text-align: center;
}

.text__right {
  text-align: right;
}

.text__left {
  text-align: left;
}

.is__obscure {
  position: absolute;
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  white-space: nowrap;
  visibility: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px);
}

.is__hidden {
  display: none;
}

hr {
  width: 100%;
  height: 1px;
  margin-top: var(--space-unit);
  margin-bottom: var(--space-25);
  color: var(--color-prim);
  background-color: var(--color-prim);
  border: none;
}

.full__bleed {
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
}