/* -- lists -- */

ul {
  list-style: none outside none;

  li {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
    word-break: keep-all;
    overflow-wrap: keep-all;
    white-space: normal;
  }

  li:before {
    content: none;
  }
}

ol {
  margin-bottom: var(--space-unit);
  list-style: decimal outside none;

  li {
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=");
    word-break: keep-all;
    overflow-wrap: keep-all;
    white-space: normal;
    counter-increment: ol-counter;
  }

  li:before {
    content: none;
  }
} 

li {
  line-height: var(--list-line-height);
  word-wrap: break-word;
}

dl {
  width: 100%;
  list-style: dash outside none;
}

dl:after {
  content: "";
  display: table;
  clear: both;
}

dd {
  padding: var(--space-xs) 0;
}

dd,
dt {
  display: inline-block;
  width: 30%;
}

dt {
  float: left;
  clear: left;
  font-weight: bold;
  text-align: right;
}

dd {
  clear: right;
  width: 70%;
  padding-left: var(--space-unit);
}

dd+dd {
  float: right;
  clear: both;
}

dt:first-child {
  padding-top: var(--space-xs);
}

dd+dt {
  clear: both;
  padding-top: var(--space-xs);
}

dt+dt {
  float: none;
  width: 100%;
  padding: 0 70% 0 0;
}

dt+dt+dd {
  margin-top: -2em;
}

dt+dt+dd+dt {
  margin-top: 2em;
}

.ticked__list {
  margin-top: var(--space-unit);
  margin-bottom: var(--space-unit);
  list-style-type: none;
  line-height: var(--list-line-height);

  li {
    word-break: break-word;

    &:before {
      content: ' ';
      position: relative;
      top: 0.35em;
      display: inline-block;
      width: var(--space-unit);
      height: var(--space-unit);
      margin-right: var(--space-xs);
      background-image: url('../../assets/img/check-regular.svg');
      background-repeat: no-repeat;
    }
  }
}

.crossed__list {
  margin-top: 0;
  margin-bottom: var(--space-unit);
  list-style-type: none;
  line-height: var(--list-line-height);

  li {
    word-break: break-word;

    &:before {
      content: ' ';
      position: relative;
      top: 0.35em;
      display: inline-block;
      width: var(--space-unit);
      height: var(--space-unit);
      margin-right: var(--space-xxs);
      background-image: url('../../assets/img/times-regular.svg');
      background-repeat: no-repeat;
    }
  }
}

.numbered__list {
  list-style: none;
  counter-reset: li;

  li {
    list-style: none !important;
    word-break: break-word;
    counter-increment: li;
    line-height: var(--list-line-height);
    margin-bottom: var(--space-xs);
    &:before {
      content: counter(li);
      display: inline-block;
      width: var(--space-unit);
      margin-right: var(--space-xs);
      margin-left: -1.5em;
      font-weight: 600;
      font-size: inherit;
      line-height: var(--list-line-height);
      text-align: right;
      direction: rtl;
      color: var(--color-prim);
      word-break: keep-all;
    }
  }
}