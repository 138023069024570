// custom styles

* {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
  margin: 0;
  padding: 0;

  *,
  *:after,
  *:before {
    box-sizing: inherit;
  }
}

html {
  overflow: scroll;
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}


html,
body {
  color: var(--font-color);
  background-color: var(--background-color);
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
  cursor: default;
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border: 2px solid var(--scrollbarBG);
  border-radius: 0;
}

@keyframes fadeSiteIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeSiteOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.js-nav-toggle {
  display: inline-block;

  @include breakpoint(md) {
    display: none;
  }
}