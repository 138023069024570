/* -- header -- */

header {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 3em 0.6em;
  overflow: hidden;
  color: var(--color-white);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .header__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    background: rgba(39, 62, 84, 0.7);
  }
  .header__content {
    position: relative;

    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    color: white;
  }
}

@include breakpoint(md) {
  header {
    padding: 5rem;
    background-attachment: fixed;
  }
}

@include breakpoint(lg) {
  header {
    padding: 8rem;
  }
}

@include breakpoint(xl) {
  header {
    padding: 12rem;
  }
}