/* -- media -- */

audio,
iframe,
img,
object,
video {
  max-width: 100%;
  margin-bottom: calc(var(--padding) * 0.5);
  object-fit: cover;
}

img {
  border-radius: var(--border-radius);
}

.thumbnail {
  padding: calc(var(--padding ) * 0.3);
  border: var(--border-width) solid var(--medium-grey);
  box-shadow: var(--box-shadow);
}