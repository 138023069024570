/* -- Form Elements -- */

form {
  background-color: transparent;

  ul li {
    display: block;
    margin-top: 0;
  }

  .form__row,
  .textarea__row {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;

    div {
      width: 100%;
      margin-top: 1em;

    }

    label {
      margin-bottom: 0.2em;
      padding: 0;
      font-size: 0.9em;
    }
  }

  .textarea__row {
    margin-top: 1em;
  }

  /* Text fields */

  textarea,
  .textarea select,
  input:not([type="checkbox"]):not([type="file"]):not([type="image"]):not([type="radio"]):not([type="range"]) {
    margin-top: 0;
    padding: 0.6em 1em;
    text-align: left;
    border: var(--border);
    border-radius: var(--border-radius);
    transition: all 0.35s ease-in-out;

    &.full__width {
      width: 100%;
    }
  }


  /* Focus */

  .textarea:focus,
  textarea:focus,
  input:not([type="checkbox"]):not([type="file"]):not([type="image"]):not([type="radio"]):not([type="range"]):focus {
    outline: 0.15em solid var(--color-prim);
    box-shadow: 0 0 0.2em var(--color-prim);
  }

  /* Read-only */

  .textarea[readonly],
  textarea[readonly],
  input:not([type="checkbox"]):not([type="file"]):not([type="image"]):not([type="radio"]):not([type="range"])[readonly] {
    border-color: rgba(0, 0, 0, 0.42);
    border-top: none;
    border-right: none;
    border-left: none;
  }

  /* Errored */

  .textarea[aria-invalid="true"],
  .textarea[aria-invalid="spelling"],
  .textarea[aria-invalid="grammar"],
  textarea[aria-invalid="true"],
  textarea[aria-invalid="spelling"],
  textarea[aria-invalid="grammar"],
  input:not([type="checkbox"]):not([type="file"]):not([type="image"]):not([type="radio"]):not([type="range"])[aria-invalid] {
    background: linear-gradient(135deg,
        rgba(255, 0, 0, 1) 0,
        rgba(255, 0, 0, 1) 0.4em,
        rgba(255, 0, 0, 1) 0.4em);
  }

  /* Required */

  .textarea[required],
  textarea[required],
  input:not([type="checkbox"]):not([type="file"]):not([type="image"]):not([type="radio"]):not([type="range"])[required] {
    border-left: 0.3em solid var(--color-orange);
  }

  input#form1_message {
    padding-bottom: 10rem;
  }

  .textarea,
  textarea {
    resize: vertical;
    margin-bottom: 1em;

    &.full__width {
      width: 100%;
    }
  }

  /* Submit */

  input[type="submit"] {
    margin: 0;
    text-align: center !important;
    color: var(--color-white);
    text-transform: uppercase;
    background: var(--color-prim);
    border-radius: var(--border-radius);
    transition: all 0.35s ease-in-out;

    &:focus,
    &:hover {
      background: var(--color-prim-darker);
      border-radius: 999px;
      cursor: pointer;
      font-weight: 600;
      letter-spacing: 0.09em;
    }

    &.full__width {
      width: 100%;
    }
  }

  select {
    font-family: var(--base-font);
    background: url('/assets/images/caret-down.svg') 98% / 15%;
    background-repeat: no-repeat, repeat;
    background-size: 10px, 15px 15px, 15px 15px, 15px 60%;
    appearance: none;
    user-select: none;

    &.full__width {
      width: 100%;
    }
  }

  input[type=date] {
    background: url('/assets/images/calendar.svg') 98% / 15%;
    background-color: transparent;
    background-repeat: no-repeat, repeat;
    background-size: 15px, 20px 20px, 20px 20px, 20px 60%;

    &.full__width {
      width: 100%;
    }
  }

  input[type=date]::-webkit-inner-spin-button {
    display: none;
  }

  input[type=date]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }


  input:-webkit-autofill,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:hover {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: var(--color-prim);
  }
}

@media only screen and (min-width: 49.9rem) {
  form {

    .form__row {
      flex-direction: row;
      justify-content: space-between;

      div:first-of-type {
        margin-right: 2%;
      }

      div:last-of-type {
        margin-left: 2%;
      }

      input:not([type="checkbox"]):not([type="file"]):not([type="image"]):not([type="radio"]):not([type="range"]) {
        flex: 1 0 220px;
      }
    }
  }
}