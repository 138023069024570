// navigation

.navigation {
  height: 80px;
  padding-top: 1rem;

  .nav-container {
    position: relative;
    max-width: auto;
    margin: 0 auto;

    .brand {
      position: absolute;
      float: left;
      padding: 0 20px;

      img {
        height: 80px;
      }

      & a,
      & a:visited {
        text-decoration: none;
      }
    }
  }

  nav {
    float: left;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        float: left;

        &.selected a {
          font-weight: 600;
          color: var(--color-white);
          background: var(--color-prim-lighter);
        }

        a {
          display: block;
          padding: 0.4em 1em;
          color: var(--color-prim);
          text-decoration: none;
          background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
          background-repeat: repeat-y;
          background-position: 0 100%;
          background-size: 0% 0em;
          transition: 600ms ease;

          &:focus,
          &:hover {
            color: var(--color-white);
            text-decoration: none;
            background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
            background-size: 100% 1em;
          }
        }
      }

      .inline {
        display: flex;
        flex-direction: row;

        .icon {
          color: var(--color-prim);
          fill: var(--color-prim);
          position: relative;
          top: 0.8rem;
          left: 0.6rem;
          margin-right: 1rem;
        }
      }
    }

    .nav-dropdown {
      position: absolute;
      z-index: 1;
      display: none;
      /* Guarantees that the dropdown will display on top of any content. */
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
    }

    .nav-mobile {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      width: 70px;
      height: 100px;
      background: var(--color-white);
    }
  }
}

@media only screen and (max-width: 768px) {
  .navigation {

    .nav-container {

      .nav-mobile {
        display: block;
      }

      nav {
        width: 100%;
        padding: 80px 0 15px;

        ul {
          display: none;

          li {
            float: none;
          }

          ul li a {
            padding: 15px;
            line-height: 20px;
          }
        }

        ul li ul li a {
          padding-left: 30px;
        }
      }
    }

    #nav-toggle {
      position: absolute;
      top: 22px;
      left: 18px;
      padding: 10px 35px 16px 0px;
      cursor: pointer;
    }

    #nav-toggle span,
    #nav-toggle span:before,
    #nav-toggle span:after {
      content: "";
      position: absolute;
      display: block;
      width: 20px;
      height: 2px;
      background: var(--color-prim-dark);
      border-radius: 1px;
      cursor: pointer;
      transition: all 300ms ease-in-out;
    }

    #nav-toggle span:before {
      top: -6px;
    }

    #nav-toggle span:after {
      bottom: -6px;
    }

    #nav-toggle.active span {
      background-color: transparent;
    }

    #nav-toggle.active span:before,
    #nav-toggle.active span:after {
      top: 0;
    }

    #nav-toggle.active span:before {
      transform: rotate(45deg);
    }

    #nav-toggle.active span:after {
      transform: rotate(-45deg);
    }
  }
}

@include breakpoint(md) {
  .navigation {
    height: 100px;

    .nav-container {

      .brand {
        position: relative;
      }

      nav {
        float: right;

        ul {
          display: flex;
          flex-flow: row wrap;
          padding-top: 0.5rem;
          .inline {
    
            .icon {
              margin-right: 0.6rem;
            }
          }
        }
      }
    }
  }
}

@include breakpoint(lg) {
  .navigation {
    height: 110px;

    .nav-container {

      nav ul {
        padding-top: 1rem;
      }
    }
  }
}