/* -- Alerts & Notifications -- */

.alert {

  margin-bottom: var(--padding);
  padding: var(--space-xs);
  border-left: 3px solid;

  &.alert__danger {
    color: var(--color-danger-darker);
    background: var(--color-danger-lighter);
    border-color: var(--color-danger);
  }

  &.alert__success {
    color: var(--color-success-darker);
    background: var(--color-success-lighter);
    border-color: var(--color-success-darker);
  }

  &.alert__warning {
    color: var(--color-warning-darker);
    background: var(--color-warning-lighter);
    border-color: var(--color-warning);
  }

  &.alert__info {
    color: var(--color-info-darker);
    background: var(--color-info-lighter);
    border-color: var(--color-info);
  }

  &.alert__empty {
    text-align: center;
    background: #f4f4f4;
    border: 1px dashed var(--silver);
  }

  p:last-child {
    margin-bottom: 0;
  }
}
