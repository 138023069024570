/* -- scroll up -- */

a.scrollup {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  padding: var(--space-xxxxs) var(--space-xxxs);
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  color: var(--color-white);
  text-decoration: none;
  background: var(--color-prim-dark);
  border-radius: var(--border-radius);
  opacity: 1;
  transition: all 0.35s var(--transition);

  &:hover {
    bottom: 0.3em;
    opacity: 0.6;
  }

  .icon {
    position: relative;
    top: var(--space-xxxxs);
    width: var(--space-unit);
    height: var(--space-unit);
    margin: 0;
  }
}

@include breakpoint(md) {
  a.scrollup {
    .icon {
      width: var(--space-md);
      height: var(--space-md);
    }
  }
}

@include breakpoint(lg) {
  a.scrollup {
    .icon {
      width: var(--space-15);
      height: var(--space-15);
    }
  }
}