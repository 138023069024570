/* -- tables -- */

table {
  width: 100%;

  th,
  td {
    padding: 5px;
    text-align: left;
  }

  th {
    border-top: 1px solid var(--color-grey-3);
    border-bottom: 1px solid var(--color-grey-3);
  }
}
