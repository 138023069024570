/* -- icons -- */

.icon {
  position: relative;
  top: 0.25rem;
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  fill: currentColor;
}

.accessibility__icon {
  position: relative;
  top: 0.25rem;
  width: 2em;
  height: 2em;
}

.warning__icon {
  position: relative;
  top: 0.25rem;
  width: 1.5em;
  height: 1.5em;
  margin-right: 0.4em;
  fill: var(--color-prim);
}