/* -- blog -- */


a.post__title {
  --max-font: 22;
  --min-font: 18;
  padding: 0;
  font-weight: 500;
  font-size: var(--responsive);
  font-family: var(--header-font);
  text-align: left;
  color: var(--color-prim);
}

.post__in__list {
  margin-bottom: 1.5em;
  padding: var(--space-xs);
  border: 1px solid var(--color-grey-2);
  border-radius: var(--border-radius);
}


.post {

  img {
    display: block;
    margin: auto 0 var(--space-15);
  }


  a {
    text-align: left;
  }

  .meta__items {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
  }

  .description {

    ol,
    ul {

      li {
        margin-left: 0;

        &:before {
          content: '-';
          margin-right: var(--space-xs);
        }
      }
    }
  }

  .hljs {
    background: var(--color-grey-11);
  }
}


.post__tags li {
  display: inline-block;
  padding: 0 var(--space-xxxs);
}

.meta .icon {
  position: relative;
  top: 0.15rem;
  width: var(--space-unit);
  height: var(--space-unit);
  margin-right: var(--space-xxxs);
  fill: var(--color-grey-3);
}

.meta__tags {
  display: inline-block;
  margin-left: 0;

  a {
    margin-right: var(--space-unit);
  }
}

.categories {
  display: inline-block;
  margin-left: 0;

  a {
    margin-right: var(--space-unit);
  }
}



.blog__nav {
  padding-left: 1em;

  a {
    margin-right: 1em;
    color: var(--color-white);
    box-shadow: none;

    &:focus,
    &:hover {
      color: var(--color-white);
      box-shadow: none;
    }

    &.disabled {
      padding: var(--space-xs) var(--space-md);
      color: var(--color-grey-4);
      text-transform: uppercase;
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      cursor: not-allowed;

      &:focus,
      &:hover {
        background-size: 0% 0em;
        border-radius: 10px;
      }
    }

    .icon {
      fill: var(--color-white);
    }
  }
}


@include breakpoint(md) {
  .meta__items {
    display: flex;
    flex-direction: row;
  }

  .blog__nav {
    max-width: 75ch;
  }
}