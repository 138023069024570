/* -- sitemap -- */

.sitemap-list {
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.sitemap-list ul li {
  text-align: left;

  &:before {
    content: ' ';
    position: relative;
    top: 0.15rem;
    display: inline-block;
    width: 0.92rem;
    height: 1rem;
    margin-right: 0.6rem;
    background-image: url('../../assets/img/angle-double-right-solid.svg');
  }
}