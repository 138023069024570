/* -- footer -- */

footer {
  display: -ms-flexbox;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  margin: 0;
  padding: var(--padding-xl) var(--padding-md);
  background: var(--color-pale);
  -ms-flex-item-align: center;

  div {
    margin: 2rem auto 0;

    ul {
      margin: 0 auto;
      display: inline-block;
      text-align: left;
    }
  }

  a {
    color: var(--color-prim);
    text-decoration: underline;
    background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
    background-repeat: repeat-y;
    background-position: 0 100%;
    background-size: 0% 0em;
    transition: 600ms ease;

    &:focus,
    &:hover {
      color: var(--color-white);
      text-decoration: none;
      background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
      background-size: 100% 1em;
    }
  }

  .icon {
    fill: var(--color-prim);
  }

  .social__links {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    align-content: center;
    color: var(--color-grey-12);

    .icon {
      display: block;
      width: 3rem;
      height: 3rem;
      margin: 1em auto;
      color: var(--color-prim);
      transition: all 0.35s var(--transition);

      &.facebook {

        &:hover,
        &:focus {
          color: #3b5998;
          transform: scale(1.2);
        }
      }

      &.twitter {

        &:hover,
        &:focus {
          color: #55acee;
          transform: scale(1.2);
        }
      }

      &.linkedin {

        &:hover,
        &:focus {
          color: #4875B4;
          transform: scale(1.2);
        }
      }
    }

    a {
      color: var(--color-prim);
      background-image: linear-gradient(transparent calc(100% - 1px), currentColor 1px);
      background-repeat: no-repeat;
      background-size: 0% 100%;
      transition: background-size .35s var(--transition);

      &:focus,
      &:hover {
        background-size: 0% 0%;
      }
    }
  }

  .brochure__download {
    h6 {
      margin-top: 0;
    }

    .icon {
      width: 3rem;
      height: 3rem;
      transition: all 0.35s var(--transition);

      &:focus,
      &:hover {
        transform: scale(1.2);
        fill: red;
      }
    }

    a {
      color: var(--color-prim);
      text-decoration: none;
      background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
      background-repeat: repeat-y;
      background-position: 0 100%;
      background-size: 0% 0em;
      transition: 600ms ease;

      &:hover {
        text-decoration: none;
        background-image: linear-gradient(var(--color-prim) 0%, var(--color-prim) 100%);
        background-size: 0% 0em;
      }
    }
  }
}

@include breakpoint(md) {
  footer {
    flex-flow: row wrap;
    justify-content: space-evenly;

    hr {
      display: none;
    }
  }
}