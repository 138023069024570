/* -- 404 -- */

h2.error-heading {
    --max-font: 24;
    --min-font: 16;
    margin: 6vmin 0 1.5vmin;
    font-size: var(--responsive);
    text-align: center;
    color: var(--color-prim);
}

@include breakpoint(md) {
    h2.error-heading{
        margin: 8vmin 0 1.5vmin;
    }
}

@include breakpoint(lg) {
    h2.error-heading{
        margin: 10vmin 0 1.5vmin;
    }
}