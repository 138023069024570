/* -- contact -- */
.contact__grid {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr;
  padding: 1em;
  margin: -1rem auto 0;

  .icon {
    fill: var(--color-prim);
    color: var(--color-prim);
  }

  .team__grid {
    margin: 0 auto;
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    .team__block {
      border: var(--border-grey-2);
      border-radius: var(--border-radius);

      img {
        min-width: 100%;
      }

      ul {
        margin: 1em;
      }
    }
  }

  .map {
    p {
      padding-left: 1em;
    }
  }

  .contact__form {

    /* -- Form -- */
    form {
      max-width: 100%;
      margin-top: -1rem;
    }

    /* -- Javascript classes -- */
    .js-hide-label label {
      top: 3.5em;
      opacity: 0;
    }

    .js-unhighlight-label label {
      color: var(--color-grey-3);
    }

    .js-show-label {
      label {
        opacity: 1;
      }
    }

    .js-error {
      border-color: var(--color-danger) !important;
    }

    .js-error+li {
      border-top-color: var(--color-danger);
    }

    .js-error label {
      color: var(--color-danger);
    }


    .button__prim.loading {
      background: var(--color-grey-2);
      cursor: not-allowed;
      opacity: 0.65;
    }

    #form1_banana {
      display: none;
    }

    .is-invalid-input {
      color: var(--color-danger);
      background: transparent;
    }

    .form-error {
      float: left;
      margin-top: 0.6em;
      margin-left: var(--space-unit);
      color: var(--color-danger);
      background: transparent;
    }

    .error_message {
      float: left;
      width: 90%;
      margin-left: var(--space-unit);
      background: transparent;
      border: 0.08rem solid var(--color-danger);
    }

    .error_message p {
      padding: 0;
      color: var(--color-danger);
    }

    .input_container {
      white-space: nowrap;
    }
  }
}

@include breakpoint(md) {
  .contact__grid {
    margin-top: -var(--space-lg);
  }}


@include breakpoint(lg) {
  .contact__grid {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 1.5rem;
    padding: 0;
    margin: -3rem auto 0;

    .staff__block {
      grid-column: 1 / 2;
      grid-row: 2;
      padding: 1rem;
      margin-left: 2%;

      .team__grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;

      }
    }

    .map {
      grid-column: 1 / 3;
      grid-row: 1;
    }

    .contact__form {
      grid-column: 2 / 3;
      grid-row: 2;
      padding: 1rem;
      margin-right: 2%;
    }
  }
}

@include breakpoint(xl) {
  .contact__grid {

    .staff__block {
      margin-left: 5%;
    }


    .contact__form {
      margin-right: 5%;
    }
  }
}

@include breakpoint(xl) {
  .contact__grid {

    .staff__block {
      margin-left: 10%;
    }


    .contact__form {
      margin-right: 10%;
    }
  }
}