// Results
.search__result__list {
    .odd {
        background: var(--color-grey-1);
    }

    li {
        margin-top: 1em;
        margin-bottom: 1em;
        padding: 0.4em;

        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }

        .excerpt {
            margin-top: 0;
            margin-bottom: 0;
        }

        .keyword {
            font-weight: 400;
            color: var(--color-prim);
            font-variation-settings: "wght"400;

        }

        p:last-of-type {
            margin-top: 0;
            margin-bottom: 0.5em;
        }
    }
}