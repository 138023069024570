/* -- typography -- */

// System fonts
$system-font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto-VF-subset.woff2');
  font-weight: 1 999;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSans';
  src: url('/assets/fonts/SourceSans-Roman.ttf');
  font-weight: 1 999;
  font-display: swap;
}


* {
  /* Typography */
  --header-font: 'Roboto', sans-serif;
  --base-font: 'SourceSans', sans-serif;
  --letter-spacing: 0.06em;
  --word-spacing: 0.08em;
  --header-position: left;
  --font-weight: 400;
  --header-font-weight: 600;

  /* Calculation */
  --responsive: calc((var(--min-font) * 1px) + (var(--max-font) - var(--min-font)) * ((100vw - 420px) / (1200 - 420)));
  --h1-max: calc(var(--h2-max) * 1.250);
  --h1-min: calc(var(--h2-min) * 1.250);
  --h2-max: calc(var(--h3-max) * 1.1250);
  --h2-min: calc(var(--h3-min) * 1.1250);
  --h3-max: calc(var(--h4-max) * 1.1250);
  --h3-min: calc(var(--h4-min) * 1.1250);
  --h4-max: calc(var(--h5-max) * 1.1250);
  --h4-min: calc(var(--h5-min) * 1.1250);
  --h5-max: calc(var(--h6-max) * 1.1250);
  --h5-min: calc(var(--h6-min) * 1.1250);
  --h6-max: calc(var(--p-max) * 1.0000);
  --h6-min: calc(var(--p-max) * 0.8888);
  --p-max: 18;
  --p-min: calc(var(--p-max) * 0.8888);
  --small-max: calc(var(--p-max) * 0.6666);
  --small-min: calc(var(--p-max) * 0.5555); 
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--header-font-weight);
  line-height: var(--line-height-header);
  font-family: var(--header-font);
  text-align: var(--header-position);
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  --letter-spacing: 0em;
  --word-spacing: 0.08em;
}

h1 {
  --max-font: var(--h1-max);
  --min-font: var(--h1-min);
  font-size: var(--responsive);
}

h2 {
  --max-font: var(--h2-max);
  --min-font: var(--h2-min);
  font-size: var(--responsive);
}

h3 {
  --max-font: var(--h3-max);
  --min-font: var(--h3-min);
  font-size: var(--responsive);
}

h4 {

  --max-font: var(--h4-max);
  --min-font: var(--h4-min);
  font-size: var(--responsive);
}

h5 {
  --max-font: var(--h5-max);
  --min-font: var(--h5-min);
  font-size: var(--responsive);
}

h6 {
  --max-font: var(--h6-max);
  --min-font: var(--h6-min);
  font-size: var(--responsive);
}

p,
li,
a,
pre,
code,
blockquote,
small {
  font-weight: inherit;
  font-family: var(--base-font);
}

p,
li,
a,
pre,
code,
blockquote {
  --max-font: var(--p-max);
  --min-font: var(--p-min);
  font-size: var(--responsive);
}

small,
.is__meta {
  --max-font: var(--small-max);
  --min-font: var(--small-min);
  font-size: var(--responsive);
}

input,
button,
.button,
.button__prim {
  margin: 0;
  padding: 0;
  --max-font: var(--p-max);
  --min-font: var(--p-min);
  font-size: var(--responsive);
  line-height: inherit;
  font-family: var(--base-font);
}

.copyright,
.copyright p,
.copyright a,
.copyright li {
  font-size: 14px;
}

@include breakpoint(lg) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  code,
  blockquote,
  small,
  .is__meta {
    font-size: calc(var(--max-font) * 1px);
  }
}

@media (max-width: 420px) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  pre,
  code,
  blockquote,
  small,
  .is__meta {
    font-size: calc(var(--min-font) * 1px);
  }
}

body,
html {
  font-weight: var(--font-weight);
  line-height: var(--line-height);
  font-family: var(--base-font);
  color: var(--font-color);
  font-style: normal;
  letter-spacing: var(--letter-spacing);
  word-spacing: var(--word-spacing);
  text-rendering: optimizeLegibility;
}

p {
  margin-bottom: 1em;
  white-space: normal;
  word-break: keep-all;
  overflow-wrap: keep-all;

  &.is__indented {
    text-indent: var(--space-unit);
  }
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline dotted;
  cursor: help;
}

small {
  font-style: italic;
}

blockquote {
  margin: 0;
  padding: var(--space-xxs);
  font-weight: var(--font-weight);
  font-style: italic;
  background-color: var(--color-grey-11);
  border-left: 2px solid var(--color-prim);
  border-radius: var(--radius);
}

::selection {
  background-color: #f5f5f5;
}

article {
  padding: 0 var(--space-sm);
}

article>*+* {
  margin-bottom: 1em;
}

.lead {
  color: var(--color-grey-5);
}

.is__lighter,
.sub__header {
  color: var(--color-grey-8);
}

.is__bold,
b,
strong {
  font-weight: 600;
}

.is__italic {
  font-style: italic;
}

.is__underlined {
  text-decoration: underline;
}

.is__capitalized {
  text-transform: capitalize;
}

.is__lowercase {
  text-transform: lowercase;
}

.is__uppercase {
  text-transform: uppercase;
}

.is__header__font {
  font-size: 1.25em;
  font-family: var(--header-font);
  font-weight: 600;
}

.is__not__underlined {
  border-bottom: none;
}

@media only screen and (max-width: 49.9rem) {
  body {
    text-rendering: optimizeSpeed;
  }
}

