:root {
  --content-width: 105ch;
  // Consistent settings
  // Borders
  --border-radius: 0;
  --border-width: 1px;
  --border: var(--border-width) solid var(--color-prim);
  --border-grey-2: var(--border-width) solid var(--color-grey-2);
  --button-border-radius: var(--border-radius);
  // Shadows
  --box-shadow: 0px 1px 1px var(--color-grey-3);
  --inner-shadow: 0px 1px 1px var(--color-grey-3);
  // Fonts
  --letter-spacing: 0.06em;
  --word-spacing: 0.08em;
  --header-position: left;
  --line-height: 1.5;
  --list-line-height: 1.8;
  --header-line-height: 1.2;
  // Paddings
  --padding-xs: 0.2rem;
  --padding-sm: 0.4rem;
  --padding: 0.7rem;
  --padding-md: 1rem;
  --padding-lg: 1.5rem;
  --padding-xl: 2rem;
  // Margins
  --margin-xs: 0.2rem;
  --margin-sm: 0.4rem;
  --margin: 0.7rem;
  --margin-md: 1rem;
  --margin-lg: 1.5rem;
  --margin-xl: 2rem;
  // Grid
  --grid-gap: 0;
  --grid-columns: 12;
  // Transitions
   --transition: ease-in-out;
  // Scrollbar
  --scrollbarBG: var(--color-pale);
  --thumbBG: var(--color-prim-dark);
}

