/*! modern-normalize */

/* Use a better box model (opinionated).*/

html {
  box-sizing: border-box;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Use a more readable tab size (opinionated).*/

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */

html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Remove the margin in all browsers.*/

body {
  margin: 0;
}

/* Improve consistency of default fonts in all browsers. (https://github.com/sindresorhus/modern-normalize/issues/3) */

body {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Helvetica,
    Arial,
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol';
}

/* Add the correct height in Firefox.*/

hr {
  height: 0;
}

/* Add the correct text decoration in Chrome, Edge, and Safari. */

abbr[title] {
  text-decoration: underline dotted;
}

/* Add the correct font weight in Chrome, Edge, and Safari.*/

b,
strong {
  font-weight: bolder;
}

/* 1. Improve consistency of default fonts in all browsers. (https://github.com/sindresorhus/modern-normalize/issues/3)
 * 2. Correct the odd 'em' font sizing in all browsers. */

code,
kbd,
samp,
pre {
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Add the correct font size in all browsers.*/

small {
  font-size: 80%;
}

/* Prevent 'sub' and 'sup' elements from affecting the line height in all browsers.*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari. */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}

/* Remove the inheritance of text transform in Edge and Firefox.
 * 1. Remove the inheritance of text transform in Firefox.*/

button,
select {
  /* 1 */
  text-transform: none;
}

/* Correct the inability to style clickable types in iOS and Safari. */

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

/* Remove the inner border and padding in Firefox.*/

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/* Restore the focus styles unset by the previous rule.*/

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/* Correct the padding in Firefox.*/

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/* Remove the padding so developers are not caught out when they zero out 'fieldset' elements in all browsers.*/

legend {
  padding: 0;
}

/* Add the correct vertical alignment in Chrome and Firefox. */

progress {
  vertical-align: baseline;
}

/* Correct the cursor style of increment and decrement buttons in Safari.*/

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/* 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.*/

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/* Remove the inner padding in Chrome and Safari on macOS.*/

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/* 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to 'inherit' in Safari.*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Add the correct display in Chrome and Safari.*/

summary {
  display: list-item;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article>*+* {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
